<script setup lang="ts">
definePageMeta({
  layout: 'marketing',
})

useSeoMeta({
  title: 'ChatPET - AI-Powered Pet Communication Platform',
  description: 'Connect with your pets through advanced AI technology. ChatPET analyzes pet behavior and enables meaningful communication between pet owners and their beloved animals. Features veterinary support and pet communication experts.',
  keywords: 'animal communication, pet chat, ai pet communication, pet understanding, veterinary support, pet behavior analysis, pet companion, ai technology',
  ogTitle: 'ChatPET - Understand Your Pet Through AI',
  ogDescription: 'Bridge the communication gap with your pets using AI-powered analysis. Get insights from veterinarians and pet communication experts.',
  ogImage: '/og-image.jpg', // Make sure to add an appropriate OG image
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <div>
    <MarketingHero />
<!--    <MarketingFeatures />-->
    <MarketingFaqSection />
<!--    <MarketingNewsletter />-->
  </div>
</template>
